<!-- ########################## <Template> ########################## -->
<template lang="">

    <Header></Header>
    <div class="pageTitle" > 
        <font-awesome-icon icon="caret-right" /> 
        Gestion des administrateurs Back-Office
    </div>

    <div class="pageBodyContainer"> 
        <!--  -->
        
        <!--  -->
        
        <div>
            <div class="card">
                <DataTable ref="dt" :value="gridData"  dataKey="id" 
                    :filters="filters"
                    responsiveLayout="scroll" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row">
                            <h5 class="p-mb-2 p-m-md-0 p-as-md-center">
                                <P-Button label="Nouvel utilisateur client" icon="pi pi-plus" class="p-button-outlined p-button-success p-mr-2" @click="openEditDialog({id:0})" />
                            </h5>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Recherche..." />
                            </span>
                        </div>
                    </template>

                    <!-- <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column> -->
                    <Column :exportable="false" style="min-width:12rem">
                        <template #body="slotProps">
                            <P-Button icon="pi pi-pencil" class="p-button-outlined p-button-rounded p-button-success p-mr-2" @click="openEditDialog(slotProps.data)" />
                            <P-Button icon="pi pi-trash" class="p-button-outlined p-button-rounded p-button-danger p-mr-2" @click="confirmDeleteSelected(slotProps.data)" />
                            <!-- <P-Button icon="pi pi-lock" class="p-button-outlined p-button-rounded p-button-help" @click="changePassword(slotProps.data)" /> -->
                        </template>
                    </Column>
                    <Column field="username" header="Login" :sortable="true" style="min-width:12rem"></Column>
                    <Column field="prenom" header="Prénom" :sortable="true" style="min-width:12rem"></Column>
                    <Column field="nom" header="Nom" :sortable="true" style="min-width:12rem"></Column>
                    <Column field="email" header="Email" :sortable="true" style="min-width:12rem"></Column>
                    <Column field="roles" header="Role" :sortable="true" style="min-width:12rem"></Column>
                    <Column field="dateDerniereConnexion" header="Dernière connexion" :sortable="true" style="min-width:12rem">
                        <template #body="slotProps">{{slotProps.data.dateDerniereConnexion?this.formatDatetime(slotProps.data.dateDerniereConnexion):""}}</template>
                    </Column>
                    <Column field="createur" header="Créé par" :sortable="true" style="min-width:12rem"></Column>

                    <template #footer>
                        Total {{gridData.length}}
                    </template>
                    
                </DataTable>
            </div>

        </div>

        <!-- Delete Dialog -->
        

        <!-- Edit Dialog -->
        <Dialog v-model:visible="showEditDialogFlag" 
            :style="{width: '450px'}" 
            :header="(selectedGridItem.id)?'Edition':'Création d\'un nouvel utilisateur'" 
            :modal="true" class="p-fluid"
            @hide="closeEditDialog"
        >
            <V-Form @submit="onSubmit" :validation-schema="myValidationSchema" autocomplete="off">
                
                <div class="p-field">
                    <label for="username">Login</label>
                    <V-Field as="InputText" name="username" v-model.trim="selectedGridItem.username"    />
                    <ErrorMessage name="username" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="prenom">Prénom</label>
                    <V-Field as="InputText" name="prenom" v-model.trim="selectedGridItem.prenom"   />
                    <ErrorMessage name="prenom" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="nom" class="p-mb-3">Nom</label>
                    <V-Field as="InputText" name="nom" v-model.trim="selectedGridItem.nom"   />
                    <ErrorMessage name="nom" class="error-feedback" />
                </div>

                <div class="p-field">
                    <label for="email" class="p-mb-3">Email</label>
                    <V-Field as="InputText" name="email" v-model.trim="selectedGridItem.email"   />
                    <ErrorMessage name="email" class="error-feedback" />
                </div>
                

                <div class="p-field">
                    <label for="userPassword"  class="p-mb-3">Mot de passe</label>
                    <V-Field name="userPassword" v-model.trim="userPassword"  v-slot="{ field, errors, errorMessage }" autocomplete="off" >
                        <Password  v-model.trim="userPassword"  autocomplete="new-password"  weakLabel="Faible" mediumLabel="Moyen" strongLabel="Fort" toggleMask>
                           
                            <template #footer="sp">
                                {{sp.level}}
                                <Divider />
                                <p class="p-mt-2">Suggestions</p>
                                <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                                    <li>Au moins un caractère en minuscule</li>
                                    <li>Au moins un caractère en majuscule</li>
                                    <li>Au moins un chiffre</li>
                                    <li>Minimum 8 caractères</li>
                                </ul>
                            </template>
                        </Password>
                     </V-Field>   
                    <ErrorMessage name="userPassword" class="error-feedback" />
                </div>
                

               <div class="p-d-flex p-jc-end">
                   
                    <button class="btn btn-danger btn-block"  @click="closeEditDialog">
                        <span>Annuler</span>
                    </button> &nbsp;
                    <button class="btn btn-primary btn-block" type="submit">
                        <span>Enregistrer</span>
                    </button>
                </div>
            
            </V-Form>
            
        </Dialog>


        <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
    </div>

    
    
</template>

<!-- ########################## <Script> ########################## -->
<script>
//--------------------------- Imports --------------------------
import AuthService from '../0-auth-components/auth.service';
import Header from "./Header.vue";
import CRUDService from "../1-common-components/crud.service";
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import { KendoDataSource } from '@progress/kendo-datasource-vue-wrapper';
import kendo from '@progress/kendo-ui'
import {_} from 'vue-underscore';
import {FilterMatchMode,FilterOperator} from 'primevue/api';
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import ConfirmDialog from 'primevue/confirmdialog';
import { Field, Form, ErrorMessage, } from 'vee-validate';
import * as yup from 'yup';
import Dropdown from 'primevue/dropdown';
import Password  from 'primevue/password';

export default {
    name: "AdminClients",
    //--------------------------- Components --------------------------
    components: {
        Header, 
        Dialog,
        Divider,
        ConfirmDialog,
        'kendo-grid': Grid,
        'kendo-grid-column': GridColumn,
        'kendo-datasource': KendoDataSource,
        'V-Field':Field,
        'V-Form':Form,
        ErrorMessage,
        Dropdown,
        Password
    },
    //--------------------------- Data --------------------------
    data() {

        // Using yup to generate a validation schema
        // https://vee-validate.logaretm.com/v4/guide/validation#validation-schemas-with-yup
        const myValidationSchema = yup.object().shape({
                
                username: yup.string().max(50,"Maximum 50").required("Login est obligatoire !"),
                prenom: yup.string().max(50,"Maximum 50").required("Prénom est obligatoire !"),
                nom: yup.string().max(50,"Maximum 50").required("Nom est obligatoire !"),
                email: yup.string().email("Format email n'est pas valide").max(50,"Maximum 50").required("Email est obligatoire !"),
                
        });


        return {
            gridData: [],
            showEditDialogFlag: false,
            selectedGridItem: {id:0},
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            },
            errorMessage: "",
            myValidationSchema,
            listeDesClients: [],
            clientRole:"Consultation",
            userPassword: ""

        } // end result
    }, //end data()
    //--------------------------- computed --------------------------
    computed: {
        isEditForm()
        {
            return this.selectedGridItem.id > 0 ? true:false;
        }
    },
    //--------------------------- Mounted --------------------------
    mounted() {
        ;
        this.log("----------- mounted()");
        this.loadGridData();
    }, //end mounted
    //--------------------------- Created --------------------------
    created() {
        this.log("----------- created()");
    },
    //--------------------------- Methods --------------------------
    methods: {
        
        //
        //========================= Manage CRUD Dialogs ===================================
        onSubmit(values, actions) {
            this.log("onSubmit");
            this.log(values);
            //manual validations
            if(this.isEditForm) {
                if(values.userPassword.length > 0)
                {
                    var r = confirm("Etes-vous sûr de vouloir modifier le mot de passe de l'utilisateur ?");
                    if (r == false) {
                        return;
                    }
                }
            }else {
                if(values.userPassword.length == 0)
                {
                    // set single field error
                    actions.setFieldError('userPassword', 'Mot de passe est obligatoire');
                    return;
                }
            }

            this.saveGridItem();
        },
        saveGridItem()
        {
            this.log("saveGridItem");
            this.log(this.selectedGridItem);

            this.selectedGridItem.application = "AdminApplication";
            this.selectedGridItem.roles = "SuperAdmin";
            if(this.selectedGridItem.id > 0)
            {
                CRUDService.update(this.selectedGridItem,'useradmin').then(
                    (response) => {
                        this.log("--------------- returned Update response.data");
                        this.log(response.data);
                        this.toastSuccess("Utilisateur "+ this.selectedGridItem.nom +" modifié avec succès");
                        this.selectedGridItemInitialState = response.data;
                        this.showEditDialogFlag = false;
                        if(this.userPassword.length > 0)
                        {
                            this.changePasswordSave(this.selectedGridItem.username,this.userPassword);
                        }
                    },
                    (error) => {
                        this.toastError("Echec sauvegarde de l''enregistrement !");
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        this.errorMessage = (error.response && error.response.data)?error.response.data:error.response;
                    }
                );
            }
            else
            {
                CRUDService.create(this.selectedGridItem,'useradmin').then(
                    
                    (response) => {
                        this.log(response.data);
                        this.gridData.push(this.selectedGridItem);
                        this.selectedGridItemInitialState = response.data;
                        this.showEditDialogFlag = false;
                        this.changePasswordSave(this.selectedGridItem.username,this.userPassword);
                        this.toastSuccess("Utilisateur "+ this.selectedGridItem.nom +" créé avec succès");
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                        this.errorMessage = (error.response && error.response.data)?error.response.data:error.response;
                        this.toastError("Echec création de l''enregistrement !");

                    }
                );
            }
        },
        openAddNewItem()
        {
            var clientId = this.listeDesClients.length > 0?this.listeDesClients[0].id:0;
            this.openEditDialog({id:0, roles:"Consultation", clientId:clientId});
        },
        openEditDialog(selectedItem){
            this.log("openEditDialog");
            this.openDialog(selectedItem);
        },
        openDialog(selectedItem){
            this.log("openDialog");
            this.log(selectedItem);
            this.showEditDialogFlag = true;
            this.selectedGridItem = selectedItem;
            this.selectedGridItemInitialState = { ...this.selectedGridItem }; //this is an object copy using decomposition
            this.userPassword = "";
        },
        closeEditDialog(){
            this.log("closeEditDialog");
            
            for (let index = 0; index < this.gridData.length; index++) {
                var element = this.gridData[index];
                if(element.id == this.selectedGridItem.id)
                {
                    this.log(this.selectedGridItemInitialState);
                    this.gridData[index] = this.selectedGridItemInitialState;
                    break;
                }
            }

            this.showEditDialogFlag = false;
        },
        confirmDeleteSelected(selectedItem){
            this.log("confirmDeleteSelected: " + selectedItem);
            this.log(selectedItem);
            this.selectedGridItem = selectedItem

            this.$confirm.require({
                message: 'Etes-vous sûr de vouloir supprimer le client '+this.selectedGridItem.nom+'?',
                header: 'Confirmation',
                icon: 'pi pi-info-circle',
                acceptLabel:'Oui',
                rejectLabel:'Non',
                acceptClass: 'p-button-danger',
                accept: () => {
                    this.deleteGridItem();
                },
                reject: () => {
                    this.$confirm.close();
                }
            });
        },
        deleteGridItem()
        {
            this.log("deleteGridItem");
            CRUDService.delete(this.selectedGridItem,'useradmin').then(
                (response) => {
                    this.log(response.data);
                    this.gridData = this.gridData.filter(d => d.id != this.selectedGridItem.id);
                    this.toastSuccess("Enregistrement supprimé avec succès");
                },
                (error) => {
                    this.toastError("Erreur suppression de l''enregisrement");
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
            
        },
        //==================================================================================
 
        loadGridData()
        {

            this.log("------loadGridData");
            CRUDService.list('useradmincustom').then(
                (response) => {
                    this.gridData = response.data;
                    this.log(this.gridData);
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },

        //----------------------------------------------------------------------
        changePasswordSave: function(userLogin, password)
        {
            this.log("AdminUser.vue => changePasswordSave");
            this.log("login: "+ userLogin);
            this.log("password: "+ password);
            var user = {
                            username: userLogin,
                            password: password
                        };

            AuthService.asyncResetAdminPassword(user).then(
                (response) => {
                    this.log("Reset success: " + response.data.token);
                    this.log("response.data: " + response.data);
                    this.errorMessage = "Mot de passe de '"+user.username+"' a été réinitialisé avec succès";
                    this.toastSuccess("Mot de passe de '"+user.username+"' a été mis à jour avec succès");
                },
                (error) => {
                    this.log("Reset error: " + error);
                    this.errorMessage = "ERREUR: Mot de pass de '"+user.username+"' n'a pas été réinitialisé !! "+error;
                    this.toastError("Mise à jour du mot de passe de '"+user.username+"' a échoué");
                }
            );


        },
        //
    }
  
    //---------------------------
} //end export default
</script>

<!-- ########################## <Style> ########################## -->
<style >


label[for="createur"] {
    display: none;
}
</style>